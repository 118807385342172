.text_small {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #4D5163;
}

.header_wrapper {
    position: relative;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 31px 0;
    max-width: 1280px;
    margin: auto;
}

.section-header-union {
    position: absolute;
    top: 106px;
    left: 0;
}

.section-header-union375 {
    display: none;
    position: absolute;
    left: -630px;
    top: -325px;
    /* overflow: hidden; */
}

/* .section-header-union375 img {
    position: relative;
    top: -121px;
} */

.header_nav {
    display: flex;
    flex-direction: row;
    gap: 52px;
    align-items: center;
}

.header_nav nav {
    display: flex;
    flex-direction: row;
    gap: 31px; /* calc(31/1920*100px); */
}

.header_authorization {
    display: flex;
    flex-direction: row;
    gap: 13.5px;
}

.header_authorization .ant-btn {
    padding: 10px 16px;
    height: 44px;
    font-family: "Montserrat", sans-serif !important;
}

.nav-link {
    text-decoration: none;
    color: #4D5163;
    font-family: 'Montserrat', sans-serif;
}

.active-link {
    font-weight: 600;
}

@media screen and (max-width: 1280px) {

    .section-header-union {
        display: none;
     }
 
     .section-header-union375 {
        display: block;
     }

    .logo img {
        height: 13px;
        width: auto;
    }

    .header_nav nav {
        display: none;
    }

    .header_authorization .ant-btn {
        padding: 6px 16px;
        height: 34px;
        width: auto !important;
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .header_wrapper {
        padding: 18px 0;
        max-width: 89%;
        /* max-width: 335px; */
    }
    
}