.offer_wrapper {
    background: #FFFFFF;
    border: 1px solid #D9DDE6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 409px;
    margin: auto;
}

.offer_wrapper .ant-card-body {
    padding: 26px 0 18px 0;
}

.offer_header, .offer_title, 
.offer_description, .offer_payment {
    max-width: 362px;
    margin: auto;
}

.offer_title .ant-col h5 {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303446;
    margin: 0;
    margin-top: 18px;
}

.offer_title .ant-col p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.8;
    margin: 0;
}

.offer_percent {
    background: #F5F7F9;
    padding: 8px 18px 8px 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.offer_percent .ant-col p {
    margin: 0;
}

.offer_description {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.offer_payment {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.offer_text_left {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6A6E7E;
    height: max-content;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.offer_text_right {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3A3545;
    height: max-content;
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
}

.offer_payment_right, .offer_payment_right_2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: right;
    color: #303446;
    height: max-content;
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
}

.offer_payment_right_2 {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.offer_payment_right_2 img {
    height: max-content;
}

.ant-card-body hr {
    margin-top: 12px;
    margin-bottom: 14px;
    width: 362px;
    border: 1px solid #EBEFF3;
}

@media screen and (max-width: 1279px) {
    .offer_header, .offer_title, 
    .offer_description, .offer_payment {
        max-width: 255px;
        margin: auto;
    }

    .offer_wrapper { 
        width: 302px;
    }

    .ant-card-body hr {
        width: 250px;
    }
}