.section-head_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    max-width: 1280px;
    margin: auto;
    padding-top: 175px;
    z-index: 1;
}

.section-head-union {
    position: absolute;
    top: calc(106px + 495px);
    left: 92.49px;
    /* left: calc( -320px + 97.49px); */
}

.section-head-union375 {
    display: none;
    position: absolute;
    left: -379px;
    top: 145px;
}

.head-community-wrapper {
    background: url('../assets/img/head-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 20px;
    margin-top: 253px;
}

.head-community {
    margin: 56px auto 24px auto;
}

.head-community-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 15px;
}

.head-community-text p, .head-community-text h5 {
    font-weight: 500;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.head-community-text img {
    height: 125px;
}

.button-community {
    color: #14C8FF;
    border: 1px solid #14C8FF;
    height: 56px !important;
    padding: 14px 24px !important;
    font-size: 20px !important;
    line-height: 28px !important;
}

.button-community:hover {
    background-color: #14C8FF;
    color: white;
}

.section1_wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    text-align: left;
    max-width: 1280px;
    margin: 72px auto 0 321px;
    position: relative;
    z-index: 1;
}

.section2_wrapper_background {
    width: 100%;
    background: linear-gradient(180deg, rgba(245, 247, 249, 0) 0%, #F5F7F9 45.25%, rgba(245, 247, 249, 0) 100%);
}

.section2_wrapper {
    padding: 168px 89px 138px 0;
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 84px;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    /* background: linear-gradient(180deg, rgba(245, 247, 249, 0) 0%, #F5F7F9 45.25%, rgba(245, 247, 249, 0) 100%); */
}

.section2_text {
    display: flex;
    flex-direction: column;
    gap: 38px;
    width: 479px;
}

.section2_text_h5 {
    color: #303446;
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 11px;
}

.section2_text_p {
    color: #4D5163;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
}

.section3_wrapper {
    position: relative;
    width: 1280px;
    margin: auto;
    padding: 115px 89px 563px 0;
    display: flex;
    flex-direction: row;
    gap: 84px;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
}

.section3_img {
    position: absolute;
    left: 295px;
    top: -33px;
}

.section3_img_rocket {
    position: relative;
    z-index: 1;
}

.section3_img_union {
    position: absolute;
    top: 0;
    left: 238px;
}
/* 
.section3_text h3 {
    margin-bottom: 31px;
}

.section3_text h5 {
    margin-bottom: 18px;
} */

.section3_table {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-bottom: 32px;
}


.section3_table p{
    color: #303446;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
}

.section3_table p span {
    color: #ED5252;
}

.section_partners {
    display: flex;
    flex-direction: column;
    gap: 46px;
    align-items: center;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 165px;
}

.section_partners h3, .section_offers h3,
.section_advantage h3 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    color: #303446;
    margin: 0;
}

.section_partners__partners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 43px;
}

.partners_img {
    width: 246px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section_offers {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 147px;
}

.section_offers h3 {
    margin-top: 0;
    margin-bottom: 64px;
}

.section_offers h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #4D5163;
    margin-top: 46px;
    margin-bottom: 32px;
}

.section_offers .ant-btn,
.advantage_text .ant-btn, 
.section3_text .ant-btn {
    padding: 14px 24px;
    width: max-content;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    height: 56px;
}

.offers_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}

.section_advantage {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 258px;
}

.section_advantage h3 {
    margin-bottom: 36px;
    max-width: 622px;
    text-align: center;
}


.advantage_wrapper {
    position: relative;
    z-index: 1;
    width: 1280px;
    display: grid;
    grid-template-areas: 
    "A B"
    "C C"
    "D D";
}

.advantage_text {
    max-width: 479px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 31px;
    grid-area: C;
    justify-self: center;
    margin-top: 62px;
    margin-bottom: 84px;
}

.advantage_text h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #4D5163;
    margin: 0;
}

#card1 {
    grid-area: A;
}

#card2 {
    grid-area: B;
    justify-self: right;
}

#card3 {
    grid-area: D;
    justify-self: center;
}

.card_advantage_wrapper {
    width: 447px;
    height: 227px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 2;
}

/* .card_advantage_wrapper .ant-card-body {
    padding: 32px 23px 31px 23px;
} */

.card_advantage_header {
    gap: 22px;
    flex-wrap: nowrap;
}

.card_advantage_icon {
    display: flex;
}

.card_advantage_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #4D5163;
    max-height: 64px;
}

.card_advantage_text {
    margin-top: 16px;
}

.card_advantage_text .ant-col {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #4D5163;
}

.section_advantage_union {
    position: absolute;
    top: 37px;
    margin: auto;
}


.footer_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1280px;
    margin: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #595D6F;
}

.footer_links {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.footer_links img {
    width: 44px;
    height: 44px;
}

.arrow-1 {
    position: absolute;
    width: 417.05px;
    height: 1px;
    left: 160px;
    top: 370px;
    opacity: 0.3;
    border: 2px dashed #ED5252;
    transform: rotate(-128.3deg);
    z-index: 1;
}

.arrow-2 {
    position: absolute;
    width: 413.51px;
    height: 1px;
    right: 160px;
    top: 370px;
    opacity: 0.3;
    border: 2px dashed #ED5252;
    transform: rotate(128.3deg);

}

.arrow-3 {
    position: absolute;
    width: 385px;
    height: 0px;
    left: 35%;
    top: 113px;
    opacity: 0.3;
    border: 2px dashed #ED5252;  
}

.carousel_offers {
    display: none;
    max-width: 375px;
    padding-bottom: 40px;
}

.slick-dots-bottom {
    bottom: -32px !important;
}

.slick-active button {
    background-color: #303446 !important;
}

.slick-dots li button {
    background-color: #595D6F !important;
}


@media screen and (max-width: 1280px) {
    
    .section-head_wrapper {
        /* width: 93.3%; */
        max-width: 350px;
        padding-top: 166px;
    }

    .section-head-union {
       display: none;
    }

    .section-head-union375 {
        display: block;
    }
    
    .section-head_wrapper h5 {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .section-head_wrapper p {
        font-size: 32px !important;
        line-height: 46px !important ;
    }

    .head-community-wrapper {
        background: url('../assets/img/head-background-375.png');
        height: 392px;
        max-width: 343px;
        margin-top: calc(189px - 16px);
    }

    .head-community {
        margin: 137px auto 36px auto;
    }

    .head-community-text {
        margin-bottom: 45.4px;
        gap: 8px;
    }

    .head-community-text p, .head-community-text h5 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .head-community-text img {
        height: 45.57px;
    }

    .section1_wrapper {
        display: flex;
        flex-direction: column;
        gap: 0;
        text-align: left;
        /* width: 93%; */
        max-width: 349px;
        margin: auto;
        margin-top: 97px;
    }

    .section1_wrapper div {
        margin-top: 0 !important;
    }

    .section1_wrapper div p,
    .section2_text h3,
    .section3_text h3 {
        font-size: 24px !important;
        line-height: 32px !important;
    }

    .section1_wrapper img {
        margin-left: -16px;
        width: 448px;
        height: 509px;
    }

    .section2_wrapper {
        padding: 31px 0 98px 0;
        /* width: 90.93%; */
        max-width: 341px;
        flex-direction: column-reverse;
        gap: 98px;
    }

    .section2_img {
        width: 100%;
        margin-left: 5px;
    }

    .section2_img img {
        object-fit: contain;
        width: 385px;
    }

    .section2_text {
        width: 100%;
        gap: 20px;
    }

    .section2_text h3 {
        margin-bottom: 12px !important;
        max-width: 318px;
    }

    .section2_text_h5 {
        margin-bottom: 4px;
    }

    .section2_text_h5, .section2_text_p,
    .section3_text h5, .section3_table p,
    .section_offers h5, .advantage_text h5, 
    .card_advantage_text .ant-col {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .section3_wrapper {
        /* width: 92%; */
        max-width: 345px;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px; 
    }

    .section3_img {
        /* position: static;
        width: 100%;
        margin-left: -43%; */
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin-left: -51%;
    }

    .section3_img_rocket {
        object-fit: contain;
        width: 705px;
    }

    .section3_img_union {
        position: absolute;
        left: 137.51px;
        object-fit: contain;
        width: 517.12px;
    }

    .section3_text {
        gap: 12px;
    }

    .section3_text h3 {
        margin-bottom: 20px !important;
    }

    .section3_table {
        gap: 24px;
    }


    .section_partners h3, .section_offers h3,
    .section_advantage h3 {
        font-size: 24px;
        line-height: 32px;
        
    }

    .section_partners {
        width: 93%;
        gap: 38px;
        padding: 16px 0 121px 0;
        margin-bottom: 0;
    }

    .section_partners__partners {
        gap: 40px 24px;
    }

    .partners_img {
        width: 160px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .partners_img img {
        width: 160px;
    }

    .section_offers {
        width: 93%;
        margin: auto;
        margin-bottom: 108px;
    }

    .section_offers h5 {
        margin-top: 36px;
        width: 273px;
    }

    .offers_container {
        padding: 0 19px;
    }

    .section_offers h3 {
        margin-bottom: 36px;
    }


    .card_advantage_title {
        font-size: 20px;
        line-height: 28px;
        height: 56px;
    }

    .card_advantage_header {
        gap: 11px;
    }

    .card_advantage_wrapper {
        width: 93%;
        height: auto;
    }

    .advantage_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    .card_advantage_icon {
        width: 56px;
        height: 56px;
    }

    .advantage_text {
        width: 273px;
        margin: 12px 13.6%;
        gap: 24px;
    }

    .footer_wrapper {
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    .footer-leads {
        text-align: center;
    }

    .arrow-1, .arrow-2, .arrow-3 {
        display: none;
    }

    .offers_container {
        display: none;
    }

    .carousel_offers {
        display: block;
        width: 100%;
    }

}

@media screen and (max-width: 375px) {
    .section-head_wrapper {
        width: 93.3%;
    }

    .section1_wrapper {  
        width: 93%;
    }

    .section2_wrapper {   
        width: 90.93%;
    }

    .section3_wrapper {
        width: 92%;
    }
}